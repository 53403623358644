import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IconContext } from "react-icons";

import classes from "./Header.module.css";
import { useEffect, useState } from "react";

import NavMenu from "./NavMenu";

const Header = () => {
  const [showNavMenu, setShowNavMenu] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (showNavMenu) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [showNavMenu]);

  return (
    <header className={classes.header}>
      <div className={classes.menuIcon}>
        <button onClick={() => setShowNavMenu(true)}>
          <IconContext.Provider value={{ size: "2rem" }}>
            <RxHamburgerMenu className={classes.hamburgerIcon} />
          </IconContext.Provider>
        </button>
      </div>
      {showNavMenu && (
        <NavMenu
          onClickOutside={() => setShowNavMenu(false)}
          headerHeight={document.querySelector("header").clientHeight}
        />
      )}
      <div className={classes.pageSelections}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? classes.navActive : classes.navInactive
          }
          end
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? classes.navActive : classes.navInactive
          }
        >
          About
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive ? classes.navActive : classes.navInactive
          }
        >
          Contact
        </NavLink>
        {/* <NavLink
          to="/news"
          className={({ isActive }) =>
            isActive ? classes.navActive : classes.navInactive
          }
        >
          News
        </NavLink> */}
        <NavLink
          to="/checkout"
          className={({ isActive }) =>
            isActive ? classes.navActive : classes.navInactive
          }
        >
          Pay
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
