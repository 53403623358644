import { useEffect, useState } from "react";
import classes from "./CardMajor.module.css";

import { supaBaseRefId } from "../util/backendUtilities";

const CardMajor = (props) => {
  const [imgDidLoad, setImgDidLoad] = useState(false);
  const {
    lineOne,
    lineTwo,
    lineThree,
    imageFile,
    blackBackground,
    imageBucket,
    onClick,
  } = props;

  const imgUrl = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/${imageBucket}/${imageFile}`;

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImgDidLoad(true);
    img.onerror = () => setImgDidLoad(false);
    img.src = imgUrl;
  });

  return (
    <div className={classes.card} onClick={onClick}>
      {imgDidLoad ? (
        <div
          className={classes.imgWrapper}
          style={{
            backgroundColor: `${blackBackground ? "black" : "#FFFFFF"}`,
          }}
        >
          <img src={imgUrl} alt={`${imageFile}`} />
        </div>
      ) : (
        <h1>{lineOne}</h1>
      )}
      <div className={classes.info}>
        {imgDidLoad && <p>{lineOne}</p>}
        <p>{lineTwo}</p>
        <p>{lineThree}</p>
      </div>
    </div>
  );
};

export default CardMajor;
