import {
  emailService,
  emailJSPubKey,
  eventRegistrationEmailTemplate,
} from "../../util/backendUtilities";

import emailjs from "@emailjs/browser";

export const sendEventRegEmail = (registrantData) => {
  
  let customData = [];
  for (const [key, value] of Object.entries(registrantData.customInputs)) {
    customData.push(`${key}: ${value}`);
  }

  const templateParams = {
    to_name: `${registrantData.firstName} ${registrantData.lastName}`,
    event: `${registrantData.event.event_title} ${new Date(
      registrantData.event.event_date
    ).getFullYear()}`,
    company: registrantData.company,
    address: registrantData.address,
    email: registrantData.email,
    phone: registrantData.phone,
    customInputs: customData,
  };

  emailjs
    .send(
      `${emailService}`,
      `${eventRegistrationEmailTemplate}`,
      templateParams,
      `${emailJSPubKey}`
    )
    .then(() => console.log("Sent"))
    .catch((err) => console.log(err.text));
};
