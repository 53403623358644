import { useContext, useState } from "react";
import classes from "./Forms.module.css";
import UserInfoContext from "../../store/UserInfoContext";
import { isInvalidInput } from "./formValidation";

const ContactInputs = () => {
  const userCtx = useContext(UserInfoContext);

  const [emailInvalid, setEmailInvalid] = useState();
  const [phoneInvalid, setPhoneInvalid] = useState();

  return (
    <div className={`${classes.gridContainer} ${classes.gridTwoCol}`}>
      <input
        type="text"
        placeholder={`${"Email"} ${emailInvalid ? "cannot be blank" : ""}`}
        value={userCtx.email}
        className={`${emailInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setEmail(e.target.value)}
        onBlur={(e) => setEmailInvalid(isInvalidInput(e.target.value, "email"))}
        onFocus={() => setEmailInvalid(false)}
      />
      <input
        type="text"
        placeholder={`${"Phone"} ${phoneInvalid ? "cannot be blank" : ""}`}
        value={userCtx.phone}
        className={`${phoneInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setPhone(e.target.value)}
        onBlur={(e) => setPhoneInvalid(isInvalidInput(e.target.value, "phone"))}
        onFocus={() => setPhoneInvalid(false)}
      />
    </div>
  );
};

export default ContactInputs;
