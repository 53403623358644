import { createContext, useState } from "react";

const UserInfoContext = createContext({
  firstName: "",
  setFirstName: () => {},
  lastName: "",
  setLastName: () => {},
  street: "",
  setStreet: () => {},
  city: "",
  setCity: () => {},
  state: "",
  setState: () => {},
  zip: "",
  setZip: () => {},
  email: "",
  setEmail: () => {},
  phone: "",
  setPhone: () => {},
  company: "",
  setCompany: () => {},
});

export const UserInfoContextProvider = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  const contextValue = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    street,
    setStreet,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    email,
    setEmail,
    phone,
    setPhone,
    company,
    setCompany,
  };

  return (
    <UserInfoContext.Provider value={contextValue}>
      {props.children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoContext;
