import { createContext, useEffect, useState } from "react";
// import axios from "axios";
// import { siteIP } from "../util/backendUtilities";

const CheckoutContext = createContext({
  items: [],
  addItem: () => {},
  removeItem: () => {},
});

export const CheckoutContextProvider = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const localStorageItems = JSON.parse(localStorage.getItem("items"));
    if (localStorageItems) {
      setItems(localStorageItems);
    }
  }, []);

  const addItem = (item) => {

    //^ (item) SHOULD BE AN OBJECT WITH KEYS NAMED itemName AND itemPrice

    const localStorageItems = JSON.parse(localStorage.getItem("items"));
    if (localStorageItems) {
      console.log("Set another item");
      localStorage.setItem(
        "items",
        JSON.stringify([...localStorageItems, item])
      );
    } else {
      console.log("Set first item");
      localStorage.setItem("items", JSON.stringify([item]));
    }
    setItems(JSON.parse(localStorage.getItem("items")));
    console.log("Updated items", items);
  };

  const removeItem = (i) => {
    console.log("before", { items });
    console.log(i);
    console.log(items[i]);
    const itemArr = items.filter((item, index) => index !== i);
    console.log("after", { itemArr });
    setItems(itemArr);
    localStorage.setItem("items", JSON.stringify(itemArr));
  };


  const contextValue = {
    items,
    addItem,
    removeItem,
  };

  return (
    <CheckoutContext.Provider value={contextValue}>
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
