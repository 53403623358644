import { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";

import classes from "./AdminPortal.module.css";

import { supaBaseKey, supaBaseURL } from "../util/backendUtilities";

import PageTitleBar from "../components/PageTitleBar";
import Login from "../components/admin/Login";
import Portal from "../components/admin/Portal";

const supabase = createClient(supaBaseURL, supaBaseKey);

const AdminPortal = () => {
  const [session, setSession] = useState(null);
  
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className={classes.page}>
      <PageTitleBar title={"ADMIN PORTAL"} />
      <div className={classes.loginContainer}>
        <div className={classes.login}>
          {!session ? (
            <Login supabaseClient={supabase} />
          ) : (
            <Portal session={session} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPortal;
