import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "./index.css";

import { AffiliateContextProvider } from "./store/AffiliateContext";
import { EventContextProvider } from "./store/EventContext";
import { CheckoutContextProvider } from "./store/CheckoutContext";
import { UserInfoContextProvider } from "./store/UserInfoContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CheckoutContextProvider>
      <EventContextProvider>
        <AffiliateContextProvider>
          <UserInfoContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </UserInfoContextProvider>
        </AffiliateContextProvider>
      </EventContextProvider>
    </CheckoutContextProvider>
  </React.StrictMode>
);
