import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Events.module.css";

// import heroImage from "./assets/event-image-mapa.png";

import EventContext from "../store/EventContext";
import PageTitleBar from "../components/PageTitleBar";
import CardMajor from "../components/CardMajor";

const Events = () => {
  const navigate = useNavigate();
  const eventCtx = useContext(EventContext);

  return (
    <div>
      <PageTitleBar title="UPCOMING EVENTS" />
      <p style={{ width: "100%", textAlign: "center", marginBottom: "2em" }}>
        <i>Click to register</i>
      </p>
      <div className={classes.grid}>
        {eventCtx.events &&
          eventCtx.events
            .filter((event) => {
              const today = new Date();
              return new Date(event.event_date) > today;
            })
            .sort((a, b) => {
              return new Date(a.event_date) - new Date(b.event_date);
            })
            .map((event) => (
              <div key={event.id}>
                <CardMajor
                  imageFile={event.event_image_filename}
                  imageBucket="event_storage"
                  lineOne={event.event_date}
                  lineTwo={event.event_time}
                  lineThree={event.event_location}
                  onClick={() => {
                    eventCtx.setSelectedEvent(event);
                    navigate("/event-registration");
                  }}
                />
                {/* <Button
                backgroundColor={"#FFA559"}
                shadowColor={"#454545"}
                title={"Register"}
                onClick={() => {
                  eventCtx.setSelectedEvent(event);
                  navigate("/event-registration");
                }}
              /> */}
              </div>
            ))}
      </div>
    </div>
  );
};

export default Events;
