import React, { useState } from "react";

import classes from "./Portal.module.css";

import { devAdminUserID } from "../../util/backendUtilities";
import EventTable from "./tables/EventTable";

const Portal = ({ session }) => {
  const [selection, setSelection] = useState(null);

  return (
    <div>
      <div className={`${classes.flex} ${classes.center}`}>
        {devAdminUserID === session.user.id && (
          <div className={classes.selection}>
            <h3>Lettings</h3>
          </div>
        )}
        <div
          className={classes.selection}
          onClick={() => setSelection(<EventTable />)}
        >
          <h3>Events</h3>
        </div>
        <div className={classes.selection}>
          <h3>Members</h3>
        </div>
      </div>
      {selection && selection}
    </div>
  );
};

export default Portal;
