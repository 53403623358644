import axios from "axios";
import { siteIP } from "../../util/backendUtilities";

import Button from "../Button";
import { sendEventRegEmail } from "../emailJS/EmailJS";

const RegisterButton = (props) => {
  const { registrantData, didComplete, setIsLoading, setSubmitMessage } = props;

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(`${siteIP}/event-register`, registrantData)
      .then((res) => {
        setIsLoading(false);
        sendEventRegEmail(registrantData);
        setSubmitMessage(
          <div>
            <h2 style={{ color: "green" }}>{res.data}</h2>
            <p style={{ textAlign: "center" }}>
              A confirmation email has been sent
            </p>
          </div>
        );
        didComplete(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setSubmitMessage(<h2 style={{ color: "red" }}>{err.response.data}</h2>);
      });
  };

  //^ this is an example of how to loop through the custom_inputs_obj returned
  //^ from the database.
  // for (const [key, value] of Object.entries(obj.custom_inputs_obj)) {
  //   console.log(key, value);
  // }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5em",
        }}
      >
        <Button
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"Register"}
          onClick={submitHandler}
        />
      </div>
    </>
  );
};

export default RegisterButton;
