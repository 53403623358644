import React, { useContext, useState } from "react";
import classes from "./Forms.module.css";

import UserInfoContext from "../../store/UserInfoContext";

import { isInvalidInput } from "./formValidation";

const NameInputs = () => {
  const userCtx = useContext(UserInfoContext);

  const [companyInvalid, setCompanyInvalid] = useState();
  const [firstNameInvalid, setFirstNameInvalid] = useState();
  const [lastNameInvalid, setLastNameInvalid] = useState();

  return (
    <div className={`${classes.gridContainer} ${classes.gridTwoCol}`}>
      <input
        type="text"
        placeholder={`${"Company Name"} ${
          companyInvalid ? "cannot be blank" : ""
        }`}
        value={userCtx.company}
        className={`${classes.gridSpanTwo} ${
          companyInvalid && classes.invalid
        }`}
        onChange={(e) => userCtx.setCompany(e.target.value)}
        onBlur={(e) => {
          setCompanyInvalid(isInvalidInput(e.target.value, "text"));
        }}
        onFocus={() => setCompanyInvalid(false)}
      />
      <input
        type="text"
        placeholder={`${"First Name"} ${
          firstNameInvalid ? "cannot be blank" : ""
        }`}
        value={userCtx.firstName}
        className={`${firstNameInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setFirstName(e.target.value)}
        onBlur={(e) => {
          setFirstNameInvalid(isInvalidInput(e.target.value, "text"));
        }}
        onFocus={() => setFirstNameInvalid(false)}
      />
      <input
        type="text"
        placeholder={`${"Last Name"} ${
          lastNameInvalid ? "cannot be blank" : ""
        }`}
        value={userCtx.lastName}
        className={`${lastNameInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setLastName(e.target.value)}
        onBlur={(e) => {
          setLastNameInvalid(isInvalidInput(e.target.value, "text"));
        }}
        onFocus={() => setLastNameInvalid(false)}
      />
    </div>
  );
};

export default NameInputs;
