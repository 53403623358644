export const loginTheme = {
  default: {
    borderWidths: {
      buttonBorderWidth: "1px",
      inputBorderWidth: "1px",
    },
    colors: {
      anchorTextColor: "white",
      anchorTextHoverColor: "darkgray",
      brand: "#FFA559",
      brandAccent: "#FFA559",
      brandButtonText: "white",
      inputBackground: "transparent",
      inputBorder: "lightgray",
      inputBorderFocus: "gray",
      inputBorderHover: "gray",
      inputLabelText: "white",
      inputPlaceholder: "darkgray",
      inputText: "white",
      messageBackground: "transparent",
      messageBackgroundDanger: "transparent",
      messageBorder: "transparent",
      messageBorderDanger: "transparent",
      messageText: "#2b805a",
      messageTextDanger: "#ff6369",
    },
    fonts: {
      bodyFontFamily: "Open Sans, sans-serif",
      buttonFontFamily: "Open Sans, sans-serif",
      inputFontFamily: "Open Sans, sans-serif",
      labelFontFamily: "Oswald, sans-serif",
    },
    radii: {
      borderRadiusButton: "7px",
      buttonBorderRadius: "7px",
      inputBorderRadius: "7px",
    },
    space: {
      anchorBottomMargin: "4px",
      buttonPadding: "10px 15px",
      emailInputSpacing: "4px",
      inputPadding: "10px 15px",
      labelBottomMargin: "8px",
      socialAuthSpacing: "4px",
      spaceLarge: "16px",
      spaceMedium: "8px",
      spaceSmall: "4px",
    },
  },
};
