import { useState } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Home.module.css";

import Button from "../components/Button";
import image from "./assets/home-page-img.jpg";
import adminPhoto from "./assets/mapa-admin-photo.jpg";
import ScrollList from "../components/ScrollList";
import LineSeparator from "../components/LineSeparator";
import streetGraphic from "./assets/mapa-street-graphic.png";
import CardMajor from "../components/CardMajor";
import { useContext, useEffect } from "react";
import EventContext from "../store/EventContext";

const Home = () => {
  const navigate = useNavigate();
  const eventCtx = useContext(EventContext);
  const [event, setEvent] = useState();

  useEffect(() => {
    setEvent(
      eventCtx.events
        .filter((evnt) => {
          return new Date(evnt.event_date) > new Date();
        })
        .sort((a, b) => {
          return new Date(a.event_date) - new Date(b.event_date);
        })[0]
    );
  }, [eventCtx.events]);

  return (
    <div className={classes.home}>
      <img
        src={image}
        alt="asphalt construction underway at night"
        className={classes.topImage}
      />
      <div
        className={`${classes.joinUs} ${classes.spaceAround} ${classes.flex}`}
      >
        <div className={classes.textAlign}>
          <div className={`${classes.flex} ${classes.centerAlign}`}>
            <img src={streetGraphic} alt="" className={classes.streetGraphic} />
          </div>
        </div>
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"Join Us!"}
          onClick={() => navigate("/membership")}
        />
      </div>
      <div
        className={`${classes.adminAboutWrapper} ${classes.flex} ${classes.spaceAround}`}
      >
        <div
          className={`${classes.adminAbout} ${classes.flex} ${classes.spaceAround}`}
        >
          <img
            src={adminPhoto}
            alt="MAPA executive director"
            className={classes.adminImage}
          />
          <div className={classes.adminMessage}>
            <h2>A MESSAGE FROM THE EXECUTIVE DIRECTOR</h2>
            <p>
              As the Executive Director, I&#39;m excited to connect with you and
              share the importance of the asphalt pavement industry in
              Mississippi. The Mississippi Asphalt Pavement Association
              represents a dedicated group of companies – asphalt producers,
              paving contractors, material suppliers, and industry professionals
              – who are committed to building and maintaining the state&#39;s
              vital transportation network.
            </p>
            <br />
            <ul>
              <li>
                <h2>Mississippi on the move</h2>
                <p>
                  Our state is experiencing a period of significant
                  infrastructure investment, with numerous road construction
                  projects underway. MAPA members are at the forefront of these
                  efforts, utilizing innovative techniques and high-quality
                  materials to deliver durable, long-lasting asphalt pavements.
                </p>
              </li>
              <li>
                <h2>Partnering for Progress</h2>
                <p>
                  MAPA is committed to working collaboratively with stakeholders
                  – government agencies, contractors, and the public – to ensure
                  these projects are completed efficiently and with minimal
                  disruption. We believe a well-maintained transportation
                  network is vital for economic growth, safety, and a high
                  quality of life for all Mississippians.
                </p>
              </li>
              <li>
                <h2>Explore Our Website</h2>
                <p>
                  This website is a valuable resource for anyone interested in
                  learning more about the asphalt pavement industry in
                  Mississippi. You&#39;ll find information about the benefits of
                  asphalt pavements, upcoming events, safety resources, and
                  details about MAPA membership. Thank you for visiting. We are
                  committed to working together to build a stronger Mississippi,
                  one road at a time.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.lists}>
        {event && (
          <div>
            <h1 style={{ color: "white", textAlign: "center" }}>
              UPCOMING EVENT
            </h1>
            <LineSeparator />
            <div className={`${classes.flex} ${classes.centerAlign}`}>
              <CardMajor
                key={event.id}
                imageFile={event.event_image_filename}
                imageBucket="event_storage"
                lineOne={event.event_date}
                lineTwo={event.event_time}
                lineThree={event.event_location}
                onClick={() => {
                  eventCtx.selectedEvent = event;
                  navigate("/event-registration");
                }}
              />
            </div>
          </div>
        )}
        <br />
        <br />
        {/* <ScrollList listType="EVENTS" /> */}
        {/*//TODO: Add back the marginTop: 10% to style*/}
        <h1 style={{ color: "white", textAlign: "center" }}>MEMBERS</h1>
        <LineSeparator />
        <ScrollList listType="AFFILIATES" />
      </div>
    </div>
  );
};

export default Home;
