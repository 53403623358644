import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./ScrollList.module.css";

import { IconContext } from "react-icons";
import { BsChevronCompactLeft } from "react-icons/bs";
import { BsChevronCompactRight } from "react-icons/bs";

import Button from "./Button";
import CardMinor from "./CardMinor";
import CardMajor from "./CardMajor";
import AffiliateContext from "../store/AffiliateContext";
import EventContext from "../store/EventContext";

import { BallTriangle } from "react-loading-icons";

// const isUpcomingEvent = (date) => {
//   let today = new Date();
//   if (date >= today) {
//     return true;
//   } else {
//     return false;
//   }
// };

const ScrollList = (props) => {
  const { listType } = props;

  const navigate = useNavigate();

  const affiliateCtx = useContext(AffiliateContext);
  const eventCtx = useContext(EventContext);

  const wrapperRef = useRef();
  const listRef = useRef();

  const [height, setHeight] = useState();

  useEffect(() => {
    if (!eventCtx.isLoading) {
      setHeight(wrapperRef.current.offsetHeight);
    }
  }, [eventCtx.isLoading]);

  return (
    <div className={`${classes.listWrapper}`} ref={wrapperRef}>
      <IconContext.Provider value={{ size: "16%" }}>
        <BsChevronCompactLeft
          style={{ top: `${height / 3}` }}
          className={`${classes.chevron} ${classes.chevronLeft}`}
          onClick={() =>
            listRef.current.scrollBy({ left: -1000, behavior: "smooth" })
          }
        />
      </IconContext.Provider>
      <IconContext.Provider value={{ size: "16%" }}>
        <BsChevronCompactRight
          style={{ top: `${height / 3}` }}
          className={`${classes.chevron} ${classes.chevronRight}`}
          onClick={() =>
            listRef.current.scrollBy({ left: 1000, behavior: "smooth" })
          }
        />
      </IconContext.Provider>
      {eventCtx.isLoading && (
        <div className={classes.loadingDiv}>
          <BallTriangle stroke="#FFA559" fill="#FFA55990" speed=".70" />
        </div>
      )}
      <div
        className={`${classes.list} ${
          eventCtx.events.length === 1 &&
          listType === "EVENTS" &&
          classes.centerAlign
        }`}
        ref={listRef}
      >
        {listType === "EVENTS" &&
          eventCtx.events &&
          eventCtx.events.map((event) => (
            <CardMajor
              key={event.id}
              imageFile={event.event_image_filename}
              imageBucket="mapa_event_images"
              lineOne={event.event_date}
              lineTwo={event.event_time}
              lineThree={event.event_location}
            />
          ))}
        {listType === "AFFILIATES" &&
          affiliateCtx.affiliates &&
          affiliateCtx.affiliates.map((aff) => (
            <CardMinor
              key={aff.id}
              imageBucket="mapa_aff_images"
              imageFile={aff.aff_image_file}
              name={aff.aff_name}
            />
          ))}
      </div>
      {listType === "EVENTS" && (
        <div className={`${classes.flex} ${classes.centerAlign}`}>
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"View All Events"}
            onClick={() => navigate("/events")}
          />
        </div>
      )}
      {listType === "AFFILIATES" && (
        <div className={`${classes.flex} ${classes.centerAlign}`}>
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"View All Members"}
            onClick={() => navigate("/affiliates")}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollList;
