import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";

import formClasses from "../forms/Forms.module.css";
import styleClasses from "./RegistrationForm.module.css";

// import CheckoutContext from "../../store/CheckoutContext";
import EventContext from "../../store/EventContext";
import UserInfoContext from "../../store/UserInfoContext";

import Button from "../Button";
import RegisterButton from "./RegisterButton";

const Certification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [didComplete, setDidComplete] = useState(false);

  const [payMethod, setPayMethod] = useState(null);

  const userCtx = useContext(UserInfoContext);
  const eventCtx = useContext(EventContext);

  // const navigate = useNavigate();
  // const checkoutCtx = useContext(CheckoutContext);

  return (
    <div className={styleClasses.page}>
      <h4 className={styleClasses.disclaimer}>
        Each person is responsible for his/her own hotel reservations.
      </h4>
      <section
        className={` ${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <h2>Certification Class</h2>
        <select name="certification" id="certification" onChange={(e) => {}}>
          <option value="Level 1">Level 1 Certification</option>
          <option value="Level 1">Level 1 Recertification</option>
          <option value="Level 1">Level 2 Rertification</option>
          <option value="Level 1">Level 2 Recertification</option>
          <option value="Level 1">CMDT Certification</option>
          <option value="Level 1">CMDT Recertification</option>
          <option value="Level 1">Master Certification</option>
        </select>
      </section>
      <section
        className={`${styleClasses.section} ${styleClasses.borderOrange}`}
        // style={{ paddingInline: "20%" }}
      >
        <div
          className={`${styleClasses.backgroundOrange} ${styleClasses.headingBackground}`}
        >
          <h3>Certificaiton Pricing</h3>
        </div>
        <div
          className={`${formClasses.gridThreeCol} ${formClasses.gridContainer}`}
        >
          <h2>Class</h2>
          <h2>Member</h2>
          <h2>Non-Member</h2>
        </div>
        {Object.entries(eventCtx.selectedEvent.price_obj)
          .sort((a, b) => a[1].sortRank - b[1].sortRank)
          .map(([key, value]) => {
            return (
              <div
                key={key}
                className={`${formClasses.gridThreeCol} ${formClasses.gridContainer}`}
              >
                <h3>{key}</h3>
                <p style={{ textAlign: "center" }}>{value["Member"]}</p>
                <p style={{ textAlign: "center" }}>{value["Non-Member"]}</p>
              </div>
            );
          })}
      </section>
      <br />
      <h3>For more information:</h3>
      <a
        href={`https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/event_storage/${eventCtx.selectedEvent.event_attachment_filename}`}
        target="about_blank"
      >
        Download the PDF registration packet
      </a>
      <br />
      <br />
      <br />
      {isLoading ? (
        <SpinningCircles stroke="#ffffff30" fill="#FFF" speed="1.0" />
      ) : (
        submitMessage
      )}
      <br />
      <br />
      {!didComplete && (
        <RegisterButton
          setIsLoading={setIsLoading}
          setSubmitMessage={setSubmitMessage}
          didComplete={setDidComplete}
          registrantData={{
            eventID: eventCtx.selectedEvent.id,
            event: eventCtx.selectedEvent,
            company: userCtx.company,
            firstName: userCtx.firstName,
            lastName: userCtx.lastName,
            address: `${userCtx.street} ${userCtx.city} ${userCtx.state} ${userCtx.zip}`,
            email: userCtx.email,
            phone: userCtx.phone,
            customInputs: {},
          }}
        />
      )}
      {didComplete && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5em",
          }}
        >
          <h2>Pay Method:</h2>
          <div
            className={styleClasses.checkbox}
            style={{ marginBottom: "2em" }}
          >
            <input
              type="radio"
              name="pay-method"
              value="Check"
              onChange={() => setPayMethod("Check")}
            />
            <label htmlFor="pay-method">Check</label>
            <input
              type="radio"
              name="pay-method"
              value="Card"
              onChange={() => setPayMethod("Card")}
            />
            <label htmlFor="pay-method">Card</label>
          </div>
          {payMethod === "Check" && (
            <div style={{ marginBottom: "5em" }}>
              <h3>Please Mail Check to:</h3>
              <p style={{ margin: "0", textAlign: "center" }}>
                Mississippi Asphalt Pavement Association
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                711 N. Presidents St.
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                Jackson, MS 39202
              </p>
            </div>
          )}
          {payMethod && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={payMethod === "Check" ? "Okay" : "Proceed to Checkout"}
              onClick={() => {
                // if (payMethod === "Check") {
                //   navigate("/");
                // } else {
                //   checkoutCtx.addItem({
                //     itemName: sponsorship,
                //     itemPrice: sponsorshipPrice,
                //   });
                //   navigate("/checkout");
                // }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Certification;
