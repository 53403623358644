export const isInvalidInput = (value, inputType) => {
  switch (inputType) {
    case "text":
      if (value === "" || value === undefined) {
        return true;
      } else {
        return false;
      }
    case "email":
      if (
        !Array.from(value).includes("@.") &&
        !Array.from(value).includes(".")
      ) {
        return true;
      } else {
        return false;
      }
    case "zip":
      if (Array.from(value).length !== 5) {
        return true;
      } else {
        return false;
      }
    case "phone":
      const arr = Array.from(value);
      let isInValid = true;
      arr.forEach((v) => {
        if (
          ![
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            "(",
            ")",
            ".",
          ].includes(v)
        ) {
          isInValid = true;
        } else {
          isInValid = false;
        }
      });

      const phoneNumber = arr.filter((n) =>
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(n))
      );
      if (phoneNumber.length !== 10) {
        isInValid = true;
      } else {
        isInValid = false
      }
      console.log(isInValid);
      return isInValid;
    default:
      break;
  }
};
