import React from "react";
import { Auth } from "@supabase/auth-ui-react";
import { loginTheme } from "./loginTheme";

import classes from './Login.module.css'

const Login = ({ supabaseClient }) => {
  return (
    <div className={classes.loginContainer}>
      <div className={classes.login}>
        <Auth
          supabaseClient={supabaseClient}
          appearance={{
            theme: loginTheme,
          }}
          providers={[]}
          view="sign_in"
          showLinks={false}
        />
      </div>
    </div>
  );
};

export default Login;
