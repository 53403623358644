import { useContext, useState } from "react";
import classes from "./Forms.module.css";
import StateInput from "./StateInput";
import UserInfoContext from "../../store/UserInfoContext";
import { isInvalidInput } from "./formValidation";

const AddressInputs = () => {
  const userCtx = useContext(UserInfoContext);

  const [streetInvalid, setStreetInvalid] = useState();
  const [cityInvalid, setCityInvalid] = useState();
  const [zipInvalid, setZipInvalid] = useState();
  const [stateInvalid, setStateInvalid] = useState();

  return (
    <div className={`${classes.gridContainer} ${classes.gridTwoCol}`}>
      <input
        type="text"
        placeholder={`${"Street"} ${streetInvalid ? "cannot be blank" : ""}`}
        value={userCtx.street}
        className={`${classes.gridSpanTwo} ${streetInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setStreet(e.target.value)}
        onBlur={(e) => {
          setStreetInvalid(isInvalidInput(e.target.value, "text"));
        }}
        onFocus={() => setStreetInvalid(false)}
      />
      <input
        type="text"
        placeholder={`${"City"} ${cityInvalid ? "cannot be blank" : ""}`}
        value={userCtx.city}
        className={`${classes.gridSpanTwo} ${cityInvalid && classes.invalid}`}
        onChange={(e) => userCtx.setCity(e.target.value)}
        onBlur={(e) => {
          setCityInvalid(isInvalidInput(e.target.value, "text"));
        }}
        onFocus={() => setCityInvalid(false)}
      />
      <input
        type="text"
        placeholder={`${"Zip"} ${zipInvalid ? "cannot be blank" : ""}`}
        // className={`${zipInvalid && classes.invalid}`}
        value={userCtx.zip}
        onChange={(e) => userCtx.setZip(e.target.value)}
        onBlur={(e) => {
          setZipInvalid(isInvalidInput(e.target.value, "zip"));
        }}
        onFocus={() => setZipInvalid(false)}
      />
      <StateInput
        value={userCtx.state}
        isInvalidInput={stateInvalid}
        onChange={(e) => userCtx.setState(e.target.value)}
        onBlur={(e) => {
          setStateInvalid(e.target.value === "");
        }}
        onFocus={(e) => setStateInvalid(false)}
      />
    </div>
  );
};

export default AddressInputs;
