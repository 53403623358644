import React, { useContext } from "react";

import EventContext from "../../../store/EventContext";

const EventTable = () => {
  const eventCtx = useContext(EventContext);
  return (
    <div>
      {eventCtx.events.map((evnt) => (
        <div key={evnt.id}>
          <p>{`${evnt.event_title} ${new Date(
            evnt.event_date
          ).getFullYear()}`}</p>
        </div>
      ))}
    </div>
  );
};

export default EventTable;
